#home {
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
    gap: 10px;
    padding-bottom: 10px;
}

#qr-codes {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    flex-wrap: wrap;
}

#qr-code__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 20px;
    padding-top: 10px;
}