:root {
    --color-primary: #524291;
    --color-secondary: #9ad45b;
    --color-background: #E1EDFF;
    --color-background-hard: #FFFFFF;
    --color-text: #000000;
    --color-text-on-primary: #FFFFFF;
}
[data-theme='dark'] {
    --color-primary: #9ad45b;
    --color-secondary: #524291;
    --color-background: #202020;
    --color-background-hard: #000000;
    --color-text: #FFFFFF;
    --color-text-on-primary: #000000;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--color-background);
    -webkit-tap-highlight-color: transparent;
}

a, h1, h2, h3, h4, h5, h6, span, p, label {
    color: var(--color-text);
    text-align: center;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.fill-space {
    flex: 1;
}

.clickable-icon {
    cursor: pointer;
    width: 30px;
    height: 30px;
}

/******************************************/
/*              Scrollbar                 */
/******************************************/
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background-color: black;
}

::-webkit-scrollbar-thumb {
    background-color: white;
}