#list {
    display: flex;
    flex-direction: column;
    margin: 20px 10px;
    gap: 10px;
    min-height: calc(100vh - 80px);
    padding-bottom: 10px;
    margin-bottom: 0;
}

#list__no-reviews {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-text);
}

#list__spinner {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}