#app {
    background-color: var(--color-background);
    min-height: 100vh;
}

header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    box-shadow: 0 0 10px 0 var(--color-primary);
    background-color: var(--color-background);
}

#header__theme-icon, #header__home-icon {
    margin: 0 10px;
    transition: all 0.2s ease-in-out;
}

#header__theme-icon:hover, #header__home-icon:hover {
    transform: scale(1.2);
}

#error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    min-height: calc(100vh - 90px);
}